(() => {
  const popUps = document.querySelectorAll(".pop");

  popUps.forEach((popUp) => {
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          popUp.classList.add("popped");
          observer.unobserve(popUp);
          return;
        }
      },
      {
        root: null,
        threshold: 0.1, // set offset 0.1 means trigger if atleast 10% of element in viewport
      }
    );

    observer.observe(popUp);
  });
})();
